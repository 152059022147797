import React, {useState,useEffect} from "react";
import { Link } from "gatsby"
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import Formulario from '../Contact.jsx'
import {group_Unities} from '../../helpers/helper.developments'
import {findEnvironments} from '../../helpers/helper.unities'
import Loading from '../../components/Loading'
import $ from 'jquery' // important: case sensitive.

const Units = (props) => {

    const location = useLocation()

    const [buttonsFilters,setButtonsFilters] = useState([])

    const [group,setGroup] = useState([])
    const [load,setLoad] = useState(false)

    const {unities} = props
    const {dispatch} = props
    const {development} = props
    const {loading} = props

    const [filters,setFilters] = useState(0)
    const [isArrowClicked, setIsArrowClicked] = useState(false)

    const query = new URLSearchParams(location.search);
    const idUnidades = query.get('unidades')?.split("-") // Obtengo IDS por URL

    const [booleanScroll,setBooleanScroll] = useState(false)

    useEffect(() => {
        if(unities){
            setGroup(group_Unities(unities,filters,idUnidades))
            setLoad(true)
        }
    },[unities,filters])

    useEffect(() => {
        if(unities){
            setButtonsFilters(findEnvironments(unities))
        }
    },[unities])

    useEffect(() => {
        if(idUnidades?.length > 0 && !booleanScroll && !loading){
            if($("#unidades")){
                $('html, body').animate({
                    scrollTop: $("#unidades").offset()?.top
                },
                500);
                setBooleanScroll(true)
            }
        }
    },[idUnidades,loading])

    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }

    return !loading ? (
        <section class={"ficha-units"} id="unidades">
            <div class="container-fluid">
                {( group.length > 0 || filters > 0 || idUnidades?.length > 0 ? 
                <>
                    <div class="row intro">
                        <div class="col-lg-2">
                            <h2 class="title-section">Unidades</h2>
                        </div>
                        <div class="col-lg-10 mt-4 mt-lg-0">
                            <p className="text-section">Gran variedad de unidades que se adaptan <br class="d-none d-lg-block" /> a todas tus necesidades y requerimientos de búsqueda.</p>
                        </div>
                        <div className="col-12">
                            <div className="d-flex flex-wrap justify-content-center filter-btn-wrapper">
                                {
                                    buttonsFilters.length > 1 && <div className={"tag-filter proyect-bg me-3 mb-3 " + (filters === 0 ? ' active ' : '')} onClick={() => updateFilter(0)}> Todos</div>
                                }
                                {
                                    buttonsFilters.length > 1
                                    && buttonsFilters.map(element => (
                                        <div className={"tag-filter proyect-bg me-3 mb-3 " + (findEnvironments(unities.objects,element) ? '' : ' d-none ' ) + (filters === element ? ' active ' : '')} onClick={() => updateFilter(element)}>{element} Ambiente{element > 1 && 's'}</div>
                                    ))
                                } 
                            </div>
                        </div>
                    </div>    
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            {group && load ? 
                                group.map((item,index) => (
                                    <div className={"unity collapsed"} data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
                                    <div className="head-row row">
                                            <div className="col-lg-2 col-3">
                                                <span className="unity-font">{item.floor}</span>
                                            </div>
                                            <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Ambientes</span>
                                            </div>
                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. cubierta </span>
                                            </div>
                                            <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. Total </span>
                                            </div>
                                            <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Precio </span>
                                            </div>
                                            <div className="col-lg-1 col-2" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                            <i className={'icon-arrow-right'}></i>
                                        </div>
                                        </div>
                                        <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                                            {item.unities.sort((a, b) => a.address?.split(" ")[1]?.localeCompare(b.address?.split(" ")[1])).map((element,index) => (                // SOLUCIÓN                         
                                                (element.status === 2 ?
                                                <>
                                                    <Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}>
                                                        <div className={"unity-row unit row " + (element.status !== 2 ? 'disabled' : '')} >
                                                            <div className="col-lg-2 col-3">
                                                                <span className={"unity-yellow-font montserrat-regular proyect-color"}>{element.address}</span>
                                                            </div>
                                                            <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {element.price > 0 ? new Intl.NumberFormat("de-DE").format(element.price) : element.price}</span>
                                                            </div>
                                                            <div className="col-2 col-lg-1 round"><i className="icon-arrow-right" ></i></div>
                                                        </div>
                                                    </Link>
                                                </>
                                            :
                                                <>
                                                    <Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}>
                                                        <div className={"unity-row unit row disabled"} >
                                                            <div className="col-lg-6 col-3">
                                                                <span className={"unity-yellow-font proyect-color"}>{element.address}  </span>
                                                            </div>
                                                            <div className="col-lg-2 col-3" style={{textAlign:'center',transform:"rotate(0deg)"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                            </div>
                                                            <div className="col-2 col-lg-1 round"><i className="icon-arrow-right" ></i></div>
                                                        </div>
                                                    </Link>
                                                </>
                                            )
                                        ))}
                                        </div>
                                    </div>
                                ))
                            :''
                            }
                            <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
                                <p className="disabled-unities text-center" style={{fontSize:"1rem",margin: "0rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
                            </div>
                        </div>
                    </div>  
                </>

                :'')}
                <div class="row contacto-main">
                    <div class="col-lg-6 col-12 intro">
                        <h2 class="mb-4">Consultanos <br class="d-none d-lg-inline-block" /> por este emprendimiento.</h2>
                        <p class="mt-lg-5">
                            Si ya tenés una propiedad en mente, no dudes<br /> 
                            en consultarnos. Con gusto responderemos<br /> 
                            todas tus dudas y te guiaremos durante
                            el proceso.
                        </p>
                    </div>
                    <div class="col-lg-6 col-12">
                        <Formulario development={development} from={'development'} />
                        <div id="alert-message"></div>
                    </div>
                </div>
            </div>

            
        </section>

    ):
    <>
        <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    unities: state.developments.unities,
    development: state.developments.development,
    loading: state.developments.loading,
}),null)(Units);