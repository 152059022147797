import React,{useRef} from 'react'
import Map from '../map'
import OwlCarousel from 'react-owl-carousel3';
import { connect } from 'react-redux'
import Breadcrumbs from "../breadcrumbs";
import SimpleReactLightbox, { SRLWrapper }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';

//Helpers
import {getCountUnities,getMinAndMaxPrice} from '../../helpers/helper.unities'
import { getLocation,getStatus, getContructionDate, getName, getTitle, getFakeAddress } from '../../helpers/helper.developments';
import {getPhotos,getDescription,getQuality, getCover } from '../../helpers/helper.rendering'
import {getTour,getLastVideo} from '../../helpers/helper.properties'

import Loading from '../../components/Loading'
import FilesPro from './files-pro';


const About = ({development,unities,loading,dispatch}) => {
    const slider = useRef()

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
        }
    }`)

    const options = {
        settings: {
            overlayColor: "rgba(0, 0, 0, 0.9)",
            autoplaySpeed: 1500,
            transitionSpeed: 900,
            disableWheelControls:true
        },
        buttons: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            iconColor: "rgba(255,255,255, 1)",
        },
        caption: {
            captionColor: "#000",
            captionFontFamily: "Raleway, sans-serif",
            captionFontWeight: "300",
            captionTextTransform: "uppercase",
        }
    };

    return !loading ? (
        <section class="ficha-about dev">
            <div class="about-top">
                <div class="container-fluid">
                    <div class="d-lg-flex align-items-lg-start justify-content-lg-between">
                        <div class="column py-4 py-lg-3 d-flex d-lg-block justify-content-start">
                            <i className="icon-building-light pe-3"></i>
                            <div class="info">
                                <h3 class="mb-1 mb-lg-3">UNIDADES</h3>
                                <p>{getCountUnities(unities)}</p>
                            </div>                    
                        </div>
                        <div class="column pb-4 pt-5 py-lg-3 d-flex d-lg-block justify-content-start">
                            <i className="icon-settings-light pe-3"></i>
                            <div class="info">
                                <h3 class="mb-1 mb-lg-3">ESTADO</h3>
                                <p>{getStatus(development)}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-3 d-flex d-lg-block justify-content-start">
                            <i className="icon-location-light1 pe-3"></i>
                            <div class="info">
                                <h3 class="mb-1 mb-lg-3">UBICACIÓN</h3>
                                <p>{getLocation(development,true)[0]} <br /> {getLocation(development,true)[1]}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-3 d-flex d-lg-block justify-content-start">
                            <i className="icon-reloj-light pe-3"></i>
                            <div class="info">
                                <h3 class="mb-1 mb-lg-3">ENTREGA</h3>
                                <p>{getContructionDate(development)}</p>
                            </div>                    
                        </div>
                        <div class="column pt-4 pb-5 py-lg-3 d-flex d-lg-block justify-content-start">
                            <i className="icon-star-light pe-3"></i>
                            <div class="info">
                                <h3 class="mb-1 mb-lg-3">CALIDAD</h3>
                                <p>Con materiales de la mejor calidad, nuestros proyectos son llevados a cabo para garantizar la durabilidad y asegurar tu inversión.</p>
                            </div>                    
                        </div>
                        <div class="column">
                            <div class="row with-border py-2">
                                <div class="col-12  ps-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-center flex-column">
                                    {unities.length === undefined ? (
                                        ''
                                    ):
                                        <> 
                                            <div class="item mb-lg-4 d-flex d-lg-block m-auto m-lg-0 mb-3">
                                                <p class="mb-lg-2 mr-4 price-p">Desde</p>
                                                <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).min)}</h4>
                                            </div>
                                            <div class="item d-flex d-lg-block m-auto m-lg-0">
                                                <p class="mr-4 mb-lg-2 price-p">Hasta</p>
                                                <h4>{"USD " + (Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max) || '??')}</h4>
                                            </div>                                    
                                        </>
                                     }
                                </div>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>  
            <div class="concepto">
                <div class="container-fluid">
                    <div class="row intro">
                        <div className="col-lg-12">
                            <Breadcrumbs
                                props={[
                                { name: realEstate.name, route: "/", location: "" },
                                { name: "Emprendimientos", route:   "/emprendimientos", location: ""},
                                { name: getName(development), route: "", location: ""},
                                ]}
                            />
                        </div>
                        <div class="col-lg-2">
                            <h2 class="title-section">Concepto</h2>
                        </div>
                        <div class="col-lg-7" >
                            <p className="text-section">{getTitle(development)}</p>
                        </div>
                    </div>     
                </div>
              
            </div>
            <div class="ficha-develompment">
                <div class="container-fluid">
                    <div class="row intro py-5 mt-lg-5">
                        <div class="col-lg-2">
                            <h2 class="title-section">Descripción</h2>
                        </div>
                        <div class="col-lg-10">
                            <p class="description-ficha">
                                {getDescription(development,99999).map((description,index) => (
                                    <>
                                        {description}
                                        <br />
                                    </>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid media-section">
                <FilesPro files={development.files} />
            </div>
            <div class="ubicacion-main">
                <div class="container-fluid">
                    <div class="row intro">
                        <div class="col-lg-2">
                            <h2 class="title-section mb-5">Ubicación</h2>
                        </div>
                        <div class="col-lg-10">
                            <p className="address">{ getFakeAddress(development)}.</p>
                            <p className="location-1">{getLocation(development,true)[0].trim()}.</p>
                            { getLocation(development,true)[1] ? <p className="location-2">{getLocation(development,true)[1].trim()}.</p> : null }
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-5 pb-lg-5 pb-3">
                            <Map coordenadas={{ geo_lat: development.geo_lat, geo_long: development.geo_long }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):
    <>
      <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    unities: state.developments.unities,
}),null)(About);